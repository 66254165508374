import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './SubStartSegmentation.module.scss';

const SubStartSegmentation = ({ heading, preamble, industrySegments }) => (
    <div className={styles['SubStartSegmentation']}>
        <div className={styles['SubStartSegmentation__Grid']}>
            {(!!heading || !!preamble) && (
                <div className={styles['SubStartSegmentation__Header']}>
                    {!!heading && (
                        <h3 className={styles['SubStartSegmentation__Title']}>
                            {heading}
                        </h3>
                    )}
                    {!!preamble && (
                        <p className={styles['SubStartSegmentation__Text']}>
                            {preamble}
                        </p>
                    )}
                </div>
            )}
            <div className={styles['SubStartSegmentation__Items']}>
                {industrySegments.map((segement, index) => (
                    <div
                        key={index}
                        className={styles['SubStartSegmentation__Item']}>
                        <Segment {...segement} />
                    </div>
                ))}
            </div>
        </div>
    </div>
);

SubStartSegmentation.propTypes = {
    heading: PropTypes.string,
    preamble: PropTypes.string,
    industrySegments: PropTypes.array,
};

SubStartSegmentation.defaultProps = {
    heading: '',
    preamble: '',
    industrySegments: [],
};

const Segment = ({
    heading = null,
    preamble = null,
    link = null,
    image = {},
}) => {
    const Container = link ? 'a' : 'div';
    return (
        <Container
            href={link}
            className={styles['SubStartSegmentation__Segment']}>
            {image?.renditions?.medium?.src && (
                <figure
                    className={styles['SubStartSegmentation__SegmentImage']}>
                    <img
                        className={
                            styles[
                                'SubStartSegmentation__SegmentImageComponent'
                            ]
                        }
                        src={image.renditions.medium.src}
                        alt={image?.alt}
                    />
                </figure>
            )}
            <p className={styles['SubStartSegmentation__SegmentTitle']}>
                {heading}
            </p>
            <p className={styles['SubStartSegmentation__Text']}>{preamble}</p>
        </Container>
    );
};

export default SubStartSegmentation;
